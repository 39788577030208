import React from 'react';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Menu from '../../components/navigation';
import ProjectsText from '../../components/projects/text';
import ProjecsGallery from '../../components/projects/gallery';
import ProjectItem from '../../components/projects/item';
import Image from '../../components/projects/image';

import logo from '../../images/projects/fliesen-rekem/logo.svg';

import screen from '../../images/projects/fliesen-rekem/screen-mockup.jpg';
import screen2x from '../../images/projects/fliesen-rekem/screen-mockup@2x.jpg';
import visu from '../../images/projects/fliesen-rekem/visu.jpg';
import visu2x from '../../images/projects/fliesen-rekem/visu@2x.jpg';
import flyer1 from '../../images/projects/fliesen-rekem/flyer1.jpg';
import flyer12x from '../../images/projects/fliesen-rekem/flyer1@2x.jpg';
import flyer2 from '../../images/projects/fliesen-rekem/flyer2.jpg';
import flyer22x from '../../images/projects/fliesen-rekem/flyer2@2x.jpg';
import flyer3 from '../../images/projects/fliesen-rekem/flyer3.jpg';
import flyer32x from '../../images/projects/fliesen-rekem/flyer3@2x.jpg';
import anhaegnger from '../../images/projects/fliesen-rekem/anhaegnger.jpg';
import anhaegnger2x from '../../images/projects/fliesen-rekem/anhaegnger@2x.jpg';
import auto from '../../images/projects/fliesen-rekem/auto.jpg';
import auto2x from '../../images/projects/fliesen-rekem/auto@2x.jpg';

const title = 'Corporate Design Relaunch für FliesenRekem';
const text =
	'Absolut professionell und sauber arbeiten die Fliesenleger des Meisterbetriebes „FliesenRekem“. Und exakt das verkörpert das neue Gestaltungskonzept des Unternehmens. Im Zuge eines umfassenden Relaunchs des firmeneigenen Corporate Designs wurde das alte Logo überarbeitet, die Gestaltung der Flyer und Autobeschriftung einer Modernisierung unterzogen sowie analog dazu die Webseite neugestaltet.';

/**
 * Page
 */
const Page = () => {
	return (
		<Layout>
			<SEO title="" />
			<Menu selected="projekte" />
			<ProjectsText text={text} title={title} />
			<div className="page-width align-center"></div>
			<ProjecsGallery>
				<ProjectItem>
					<img src={logo} alt="" />
				</ProjectItem>
				<ProjectItem>
					<Image src={screen} src2={screen2x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={visu} src2={visu2x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={flyer1} src2={flyer12x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={flyer2} src2={flyer22x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={flyer3} src2={flyer32x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={auto} src2={auto2x} />
				</ProjectItem>
				<ProjectItem>
					<Image src={anhaegnger} src2={anhaegnger2x} />
				</ProjectItem>
			</ProjecsGallery>
		</Layout>
	);
};

export default Page;
